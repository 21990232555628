<template>
    <div>
        <el-breadcrumb class="Bdh" separator-class="el-icon-arrow-right">
            <el-breadcrumb-item>社区管理</el-breadcrumb-item>
            <el-breadcrumb-item>帖子管理</el-breadcrumb-item>
        </el-breadcrumb>
        <div class="add">
            <!-- 选择板块： -->
            <el-select v-model="select" placeholder="请选择一级板块" @change="change" style="width:150px;margin-left:0;" size="small">
                <el-option v-for="item in options" :key="item.value" :label="item.name" :value="item.id"></el-option>
            </el-select>
            <el-select v-model="select2" placeholder="请选择二级板块" @change="change2" style="width:150px;margin-left: 10px;" size="small">
                <el-option v-for="item in options2" :key="item.value" :label="item.name" :value="item.id">
                </el-option>
            </el-select>
            <!-- 选择审核状态： -->
            <el-select v-model="select3" placeholder="请选择审核状态" style="width:100px;margin-left: 20px;" size="small">
                <el-option v-for="item in options3" :key="item.value" :label="item.name" :value="item.id"></el-option>
            </el-select>
            <el-button type="primary" style="margin-left:10px;margin-right: 0;" @click="query" size="small">查询</el-button>
            <el-button style="margin-left:10px" @click="release" size="small">发布</el-button>
        </div>
        <el-table :data="list" border style="width: 100%">
            <el-table-column prop="id" v-if="false"></el-table-column>
            <el-table-column prop="title" label="标题"></el-table-column>
            <el-table-column label="作者姓名" prop="author_name"></el-table-column>
            <el-table-column label="创建时间" prop="create_time">
                <template slot-scope="scope">{{scope.row.create_time.split(' ')[0]}}</template>
            </el-table-column>
            <el-table-column label="评论数" prop="comment_count"></el-table-column>
            <el-table-column label="点赞数" prop="like_count"></el-table-column>
            <el-table-column label="阅读数" prop="view_count"></el-table-column>
            <el-table-column label="是否是精华帖子" prop="di">
                <template slot-scope="scope">
                    <span v-if="scope.row.di == 0">否</span>
                    <span v-else>是</span>
                </template>
            </el-table-column>
            <el-table-column width="220">
                <template slot-scope="scope">
                    <div class="opt doc">
                        <el-button size="mini" type="success" icon="el-icon-view" v-if="scope.row.is_audit == 0" @click="lookup(scope.row)">审核</el-button>
                        <el-button size="mini" type="warning" icon="el-icon-view" v-if="scope.row.is_audit == 1" @click="lookup(scope.row)">查看 </el-button>
                        <el-button size="mini" type="primary" icon="el-icon-edit-outline" @click="edit(scope.row)">修改</el-button>
                    </div>
                </template>
            </el-table-column>
        </el-table>
        <p class="paging">
            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="current"
                :page-sizes="pageSizes" :page-size="PageSize" layout="total, sizes, prev, pager, next, jumper"
                :total="totalCount" :hide-on-single-page="value"></el-pagination>
        </p>
    </div>
</template>
<script>
    export default {
        data() {
            return {
                list: [],
                current: 1,//默认显示第几页
                totalCount: 0,// 总条数，根据接口获取数据长度
                pageSizes: [20],// 个数选择器（可修改）
                PageSize: 20,// 默认每页显示的条数（可修改）
                value: false,
                options: [],
                select: '',
                options2: [],
                select2: '',
                options3: [
                    { id: 0, name: '未审核' },
                    { id: 1, name: '审核' },
                ],
                select3: 0
            }
        },
        mounted() {
            this.getbk();
            let aa = JSON.parse(sessionStorage.getItem('lthistory'));
            if (aa) {
                this.getdata(aa.page, aa.forum_id,aa.audit);
                this.select = aa.one;
                this.change(this.select);
                this.select2 = aa.forum_id;
                this.select3 = aa.audit;
            }
        },
        methods: {
            getbk() {
                this.axios.get('/bbs/get_gu_bbs_forum?forum_id=' + 0)
                    .then((res) => {
                        if (res.data.code == 0) {
                            this.options = res.data.result;
                        } else {
                            console.log(res);
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            },
            change(value) {
                this.options2 = [];
                this.select2 = '';
                this.axios.get('/bbs/get_gu_bbs_forum?forum_id=' + value)
                    .then((res) => {
                        if (res.data.code == 0) {
                            this.options2 = res.data.result;
                        } else {
                            console.log(res);
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            },
            change2(value) { },
            getdata(page, value,type) {
                this.axios.get("/bbs/get_gu_bbs_post?page=" + page + "&size=" + this.PageSize + "&forum_id=" + value + "&audit=" + type)
                    .then((res) => {
                        if (res.data.code == 0) {
                            this.totalCount = res.data.count;
                            this.list = res.data.result;
                            sessionStorage.setItem('lthistory', JSON.stringify({ page: page, forum_id: value, audit: this.select3, one: this.select }));
                        } else {
                            console.log(res)
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            },
            handleSizeChange() { },
            handleCurrentChange(currentPage) {
                this.getdata(currentPage, this.select2,this.select3);
            },
            lookup(row) {
                this.$router.push({ path: '/postManage/postDetail', query: { id: row.id } })
            },
            query() {
                this.totalCount = 0;
                this.list = [];
                this.getdata(1, this.select2,this.select3);

            },
            release() {
                this.$router.push('/postManage/postRelease');
            },
            edit(row) {
                this.$router.push({ path: '/postManage/postRelease', query: { id: row.id } })
            }
        },
        // destroyed() {
        //     sessionStorage.removeItem('lthistory');
        // },
    }
</script>
<style>
    .Bdh {
        height: 50px;
        width: 100%;
        line-height: 40px;
    }

    .hdtc {
        width: 80%;
    }

    .add {
        width: 100%;
        height: 50px;
        text-align: left;
    }

    .opt a {
        cursor: pointer;
        color: green;
        margin-right: 20px;
        display: inline-block;
    }

    .add .el-button {
        margin-right: 20px;
    }
</style>